import { computed, watchEffect } from 'vue';
import { useStore } from 'vuex';

import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import { getMetric } from '@/utils/metrics';
import metricsModule from '@/store/modules/metrics';
import type { MetricDescriptor } from '@/types/MetricDescriptor';

const useSpotlightPrimaryMetric = () => {
  const store = useStore();
  const metrics = store.getters.metrics;

  const queryParams = useSpotlightQueryParams();

  const primaryMetric = computed(() => {
    let metric = queryParams.metric.toValue();

    // Connection category has metricSubtype only, but the metrics selector looks for `metricSubtype_overall` to figure out the label
    if (!metric.includes('_')) {
      metric = `${metric}_overall`;
    }

    return getMetric(metrics, metric) as MetricDescriptor;
  });

  // This is here to limit refactoring taking part in DCI-3805
  watchEffect(() => {
    store.commit(metricsModule.types.SET_PRIMARY, primaryMetric.value.key);
  });

  return primaryMetric;
};

export default useSpotlightPrimaryMetric;
