import omit from 'lodash/omit';
import { MetricResponse } from '@/types/MetricResponse';
import { getNextNiceNumber } from '@/utils/data';
import { type Operator } from '@/types/Operator';
import { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { DatasetsAPIDataStructuresEnum, MetricStructuresEnum } from '@/types/MetricStructures';
import { PaginatedMetricResponse } from '@/types/PaginatedMetricResponse';

/** Used for chart ranges, like Y-Axis bounds */
export type MinMax = {
  min: number;
  max: number;
};

export type MinMaxBuilderArgs = Partial<{
  operators: Operator[];
  mainOperator: Operator;
  connectionCategory: string;
  selectedConnectionCategories: AvailableConnectionCategory[];
}>;

/**
 * A function that returns a MinMax builder. Needs to return a function so it can be more
 * reactive and be called repeatedly when options change.
 */
export type MetricMinMaxBuilder<Response> = (args: MinMaxBuilderArgs) => (response: Response) => MinMax;

/**
 * Used to ensure a chart shows 0-100 for its range (usually y-axis)
 */
export const getPercentageChartMinMax: MetricMinMaxBuilder<any> = () => () => {
  return {
    min: 0,
    max: 100,
  };
};

/**
 * Grabs the min and maximum across all selected operators for this metric
 */
export const getConfidenceIntervalMinMax: MetricMinMaxBuilder<
  MetricResponse<MetricStructuresEnum.ConfidenceCounts>
> = ({ operators }: MinMaxBuilderArgs) => {
  return (response: MetricResponse<MetricStructuresEnum.ConfidenceCounts>) => {
    let results;
    if (!operators || operators.length === 0) {
      results = response.results;
    } else {
      const operatorIds = operators.map((operator) => operator.canonical_network_id);
      results = response.results.filter((result) => operatorIds.includes(result.canonical_network_id));
    }

    return {
      min: 0,
      max: getNextNiceNumber(
        Math.max(
          1,
          ...results.map((result) => {
            return Math.max(...(Object.values(result.counts) as number[]));
          }),
        ),
      ),
    };
  };
};

/**
 * Grabs the min and maximum means/averages across all operator for this metric, when split by IP.
 */
export const getMeanIPMinMax: MetricMinMaxBuilder<MetricResponse<MetricStructuresEnum.CdnIp>> = ({
  operators,
}: MinMaxBuilderArgs) => {
  return (response: MetricResponse<any>) => {
    let results;
    if (!operators || operators.length === 0) {
      results = response.results;
    } else {
      const operatorIds = operators.map((operator) => operator.canonical_network_id);
      results = response.results.filter((result) => operatorIds.includes(result.canonical_network_id));
    }

    return {
      min: 0,
      max: getNextNiceNumber(
        Math.max(
          1, // if there's no other values for some strange reason, fall back to 1 for the maximum, instead of undefined
          ...results.map((result: Record<string, any>) => {
            return Math.max(
              ...Object.values(result.mean).map((ip: any) => {
                return Math.max(...(Object.values(ip) as number[]));
              }),
            );
          }),
        ),
      ),
    };
  };
};

export const getPerformanceDriversMinMax: MetricMinMaxBuilder<
  PaginatedMetricResponse<
    DatasetsAPIDataStructuresEnum.EcqScorePerformanceDrivers | DatasetsAPIDataStructuresEnum.CcqScorePerformanceDrivers
  >
> = ({ operators }: MinMaxBuilderArgs) => {
  return (response) => {
    let results;
    if (!operators || operators.length === 0) {
      results = response.data;
    } else {
      const operatorIds = operators.map((operator) => operator.canonical_network_id);
      results = response.data.filter((result) => operatorIds.includes(result.canonical_network_id));
    }

    // get values from each result; flat map will make a single array of results across all operators and bars
    const allColumnValues = results.flatMap((result) => {
      // remove the non-data columns from the result
      const values = omit(result, ['canonical_network_id', 'location', 'date', 'ecq', 'ccq']) as Record<string, number>;
      return Object.values(values);
    });
    const minimumBarValue = Math.min(...allColumnValues);
    const maximumBarValue = Math.max(...allColumnValues);

    return {
      min: Math.max(0, Math.round(minimumBarValue - 10)), // ensure the minimum is at least 0, and give a little padding
      max: Math.round(maximumBarValue),
    };
  };
};
