import { USER_GROUPS } from '@/constants/constants';
import { MetricDescriptor } from '@/types/MetricDescriptor';

type UserGroup = (typeof USER_GROUPS)[number];

const getSpotlightUserGroups = (
  metrics: MetricDescriptor[],
  selectedConnectionCategory: string,
  selectedMetric?: MetricDescriptor,
) => {
  const isGroupAvailable = (group: UserGroup) => metrics.some((m) => m.subcategory === group.value);

  return USER_GROUPS.filter((group) => group.supportedTypes.includes(selectedConnectionCategory))
    .filter(isGroupAvailable)
    .map((group) => {
      return {
        ...group,
        active: group.value === selectedMetric?.subcategory,
      };
    });
};

export default getSpotlightUserGroups;
