import { computed } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import type { RefLike } from '@/types/helpers/RefLike';

import usePolygons from '@/composables/usePolygons';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import { MetricStructures, MetricStructuresEnum } from '@/types/MetricStructures';
import { getDatumValue } from '@/utils/viewHelpers';

const usePolygonsWithMetrics = <T extends MetricStructuresEnum>(
  dashboard: Dashboards,
  options: Omit<Parameters<typeof useGeocodingConfig>[1], 'enabled'> & { enableGeocodingConfigQuery: RefLike<boolean> },
) => {
  const { countryIso3, enableGeocodingConfigQuery, geocoding, ...geocodingConfigRest } = options;

  const polygonsQuery = usePolygons(dashboard, { geocoding, countryIso3 });
  const geocodingConfigQuery = useGeocodingConfig<T>(dashboard, {
    geocoding,
    countryIso3,
    ...geocodingConfigRest,
    operatorInfo: true,
    enabled: computed(() => {
      if (enableGeocodingConfigQuery) {
        return enableGeocodingConfigQuery.value && polygonsQuery.isSuccess.value;
      }

      return polygonsQuery.isSuccess.value;
    }),
  });

  const polygonsWithMetrics = computed(() => {
    if (!geocodingConfigQuery.data.value || !polygonsQuery.data.value) {
      return null;
    }

    const geocodingConfigData = geocodingConfigQuery.data.value.data;
    const polygonData = polygonsQuery.data.value.data;

    const geocodingConfigByLocation = geocodingConfigData.results.reduce<{
      [key: number]: MetricStructures[T][];
    }>((acc, geocodingConfig) => {
      acc[geocodingConfig.location] = acc[geocodingConfig.location] || [];
      acc[geocodingConfig.location].push(geocodingConfig);

      return acc;
    }, {});

    const result = polygonData.features.map((polygon) => {
      const polygonMetrics = geocodingConfigByLocation[polygon.id] || [];

      return {
        ...polygon,
        item: polygonMetrics.map((item) => {
          return {
            ...item,
            value: getDatumValue(item),
            operatorInitial:
              geocodingConfigQuery.data.value?.data.operators?.[item.canonical_network_id]?.name_mapped[0],
          };
        }),
      };
    });

    return {
      bbox: polygonData.bbox,
      features: result,
    };
  });

  return {
    polygonsWithMetrics,
    polygonsQuery,
    geocodingConfigQuery,
  };
};

export default usePolygonsWithMetrics;
