<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { onBeforeRouteUpdate, RouterView } from 'vue-router';

import { useStore } from 'vuex';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import { DEFAULT_USER_GROUP } from '@/constants/constants';
import { Dashboards } from '@/constants/dashboards';
import useAnalytics from '@/composables/useAnalytics';
import useUser from '@/components/onx/composables/useUser';
import IntlSpotlightMenu from '@/intl-spotlight/IntlSpotlightMenu.vue';
import IntlSpotlightBreadcrumbs from '@/intl-spotlight/IntlSpotlightBreadcrumbs.vue';

const vuexStore = useStore();
const mainMetric = vuexStore.getters['metrics/primaryMetric'];
const userGroup = vuexStore.getters['competitive/userGroup'];

const { track } = useAnalytics();
const matches = useBreakpoints();

const ready = ref(false);

onBeforeRouteUpdate((to, from) => {
  if (from.name === to.name) {
    track('multicountry-spotlight:route-update', {
      dashboard: 'spotlight',
      params: to.params,
      query: to.query,
      url: to.path,
      route: to.name,
    });
  }
});

const { setUser, setUserSettings, user } = useUser();
onMounted(async () => {
  if (!user.value.id) {
    const res = await setUser();

    if (res) {
      vuexStore.dispatch('checkDisclaimer');
    }
  }

  await setUserSettings(Dashboards.Spotlight);

  ready.value = true;
});

watch(
  () => mainMetric,
  () => {
    vuexStore.dispatch('setUserGroup', mainMetric?.subcategory || userGroup || DEFAULT_USER_GROUP);
  },
);
</script>

<template>
  <div class="spotlight">
    <div v-if="matches.laptop.value" class="spotlight__sidebar">
      <OnxSidebar>
        <IntlSpotlightMenu />
      </OnxSidebar>
    </div>

    <div v-if="ready" class="spotlight__main">
      <IntlSpotlightBreadcrumbs />
      <RouterView name="header" />
    </div>
  </div>
</template>
