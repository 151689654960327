<script setup lang="ts">
import { computed } from 'vue';

import ThreeSixtyPage from '@/360/ThreeSixtyPage.vue';
import { threeSixtyMapDefinitionsByPage } from '@/360/MapMetricDefinitions';
import use360ConnectionCategories from '@/360/use360ConnectionCategories';
import type { GroupedChartMetricDefinitions } from '@/chart-metric-definitions/ChartMetricDefinitions';
import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import type { ChartMetricDefWithConnectionCategory } from '@/chart-metric-definitions/MetricDefinition';
import { MapOperatorSelector, useMapOperatorSelector } from '@/components/map-operator-selector';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import { MapEvents } from '@/components/visual/map/MapEvents';
import useCheckIfMetricOrDatasetKeyExists from '@/composables/useCheckIfMetricOrDatasetKeyExists';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { METRIC_TYPES } from '@/constants/constants';
import { Dashboards } from '@/constants/dashboards';
import Routes from '@/constants/routes';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';

const { checkIfMetricOrDatasetKeyExists } = useCheckIfMetricOrDatasetKeyExists();

const chartMetrics = chartMetricDefinitionsByPage[
  Routes.ThreeSixtyCoverageNetworkCoverage
] as GroupedChartMetricDefinitions;
const overallMetrics = chartMetrics.overall as ChartMetricDefWithConnectionCategory[];

const mapMetrics = threeSixtyMapDefinitionsByPage[Routes.ThreeSixtyCoverageNetworkCoverage];

const individualConnectionCategoryCountsByOperator = chartMetrics.individualConnectionCategoryCountsByOperator;

const { locationId } = useLocations(Dashboards.ThreeSixty);
const { aggregation } = useFilters(Dashboards.ThreeSixty);
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);
const connectionCategories = use360ConnectionCategories();
const { selectedMapOperator } = useMapOperatorSelector(Dashboards.ThreeSixty);

const mapBboxFilterStore = useMapBboxFilterStore();

const selectedConnectionCategoriesStrArray = computed(() => {
  return connectionCategories.value.selectedChartConnectionCategories.map((c) => c.categoryValue);
});
</script>

<template>
  <ThreeSixtyPage class="onx-360-network-coverage" v-if="selectedMapOperator">
    <OnxSelectorBlock title="Map filters">
      <MapOperatorSelector />
    </OnxSelectorBlock>

    <div class="onx-grid">
      <OnxSwyftMap
        v-for="metric in mapMetrics"
        :key="`${locationId}_${metric.id}_${mapBboxFilterStore.resetKey}`"
        :map-endpoint="metric.endpoint"
        :title="metric.title"
        :network-operators="[selectedMapOperator]"
        :connection-categories="selectedConnectionCategoriesStrArray"
        :class="metric.chartContainerClass"
        :geohash-level="7"
        :location-id="locationId"
        @[MapEvents.NewBounds]="mapBboxFilterStore.handleNewBounds"
        enable-bbox-filtering
      />
      <template v-for="metric in overallMetrics">
        <ChartManager
          v-if="checkIfMetricOrDatasetKeyExists(`${metric.metricSubtype}_${METRIC_TYPES.Overall}`)"
          :key="`${metric.metricSubtype}_${metric.connectionCategory}`"
          :dashboard="Dashboards.ThreeSixty"
          :metric="metric"
          :connection-category="metric.connectionCategory"
          :location="locationId"
          :geohashes="[]"
          :aggregation="aggregation"
          :operators="selectedOperators"
          :selected-connection-categories="connectionCategories.selectedChartConnectionCategories"
          :main-operator="selectedMapOperator"
          :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
        />
      </template>
    </div>

    <div class="onx-grid fluid fit">
      <template v-for="metric in individualConnectionCategoryCountsByOperator">
        <template v-for="connectionCategory in connectionCategories.selectedChartConnectionCategories">
          <template
            v-if="checkIfMetricOrDatasetKeyExists(`${metric.metricSubtype}_${connectionCategory.categoryValue}`)"
          >
            <ChartManager
              :key="`${metric.metricSubtype}_${connectionCategory.categoryValue}`"
              :dashboard="Dashboards.ThreeSixty"
              :metric="metric"
              :connection-category="connectionCategory.categoryValue"
              :connection-category-label="connectionCategory.categoryLabel"
              :location="locationId"
              :geohashes="[]"
              :aggregation="aggregation"
              :operators="selectedOperators"
              :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
            />
          </template>
        </template>
      </template>
    </div>
  </ThreeSixtyPage>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.onx-360-network-coverage {
  & .chart-height {
    height: 575px;
  }

  .coverage-map__map {
    height: 100%;
  }

  .coverage-map__map-wrapper {
    height: calc(100% - 42px);
  }
}
</style>
