<script setup lang="ts">
import { type AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { ComponentPublicInstance, computed, ref, toRefs } from 'vue';

import { OnxChartBaseProps } from '@/components/onx/charts/OnxChartBaseProps';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import BarChart from '@/components/visual/chart/BarChart.vue';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';
import useDelayedLoader from '@/composables/useDelayedLoader';
import useMetricSource from '@/composables/useMetricSource';
import { useMetricSQL } from '@/composables/useMetricSQL';
import type { DateIntervalEnum } from '@/types/MetricRequestParameters';
import getElementContentWidth from '@/utils/getElementContentWidth';
import naiveId from '@/utils/naiveId';
import { MetricResponse } from '@/types/MetricResponse';
import { MinMax } from '@/chart-metric-definitions/data-transformers/getMinMax';

import type { DataTransformerReturnedItem } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';

export type Props = OnxChartBaseProps & {
  enabled?: boolean;
  geohashes: string[];
  stacked?: boolean;
  transform: (
    data: any,
    horizontal?: boolean,
  ) => DataTransformerReturnedItem[] | { datasets: DataTransformerReturnedItem[]; labels: string[]; min?: number };
  nbDays?: number;
  dateInterval?: DateIntervalEnum;
  onExportToCsv?: (data: any, title: string, params?: any) => void | Promise<void>;
  horizontal?: boolean;
  max?: number;
  chartConfig?: any;
  chartTooltipPlugin?: (context: any, tooltip: any, chartData: any) => void;
  chartTooltipShowSecondaryValue?: boolean;
  dynamicHeight?: boolean;
  otherRequestParams?: AxiosRequestConfig['params'];
  barChartAttrs?: Record<string, unknown>;
};

const props = withDefaults(defineProps<Props>(), {
  enabled: undefined,
  stacked: true,
});

const {
  aggregation,
  bbox,
  chartConfig,
  chartSubtitle,
  chartTitle,
  chartTitleTooltip,
  endDate,
  geohashes,
  location,
  nbDays,
  screenshotSubtitle,
  screenshotTitle,
} = toRefs(props);

const container = ref<ComponentPublicInstance | null>(null);
const chartWidth = computed(() => {
  if (container.value) {
    return getElementContentWidth(container.value.$el);
  }

  return 0;
});

const query = props.queryFn({
  nbDays: nbDays.value ?? 90,
  endDate,
  otherRequestParams: props.otherRequestParams,
  dateInterval: props.dateInterval,
});

const {
  query: { data: response, isLoading, isPending, isRefetching, isSuccess },
} = query;

const { metricSource } = useMetricSource(response);
const { sql } = useMetricSQL(response, isSuccess);
const minMax = computed<MinMax | undefined>(() => {
  if (isSuccess.value && response.value && props.calculateBounds) {
    return props.calculateBounds(response.value.data as MetricResponse<any>);
  } else {
    return undefined;
  }
});

const transformedData = computed(() => {
  if (!isSuccess.value) {
    return { labels: [], datasets: [] };
  }

  const data = response.value?.data;
  if (!data) {
    return { labels: [], datasets: [] };
  }

  return props.transform(data, props.horizontal);
});

const barSeries = computed(() => {
  const transformedDataIsArray = Array.isArray(transformedData.value);
  const labels = !transformedDataIsArray
    ? transformedData.value.labels
    : [
        ...new Set(
          transformedData.value.flatMap((item: any) => {
            return item.data.map((datum: any) => (props.horizontal ? datum.y : datum.x));
          }),
        ),
      ];

  return {
    labels,
    datasets: transformedDataIsArray ? transformedData.value : transformedData.value.datasets,
  };
});

const computedHeight = computed(() => {
  if (!barSeries.value?.labels?.length || !props.dynamicHeight) {
    return '100%';
  }

  return `${barSeries.value.labels.length * 30}px`;
});

const getTooltipTitle = (title: string) => title;
const screenshotLegend = computed(() => {
  if (!barSeries.value || barSeries.value.datasets.length === 0) {
    return [];
  }

  return barSeries.value.datasets.map((dataset: any) => {
    return (
      dataset.meta?.imageExportLegend || {
        label: dataset.label,
        color: dataset.backgroundColor,
      }
    );
  });
});

const randomChartId = naiveId();
const chartId = computed(() => {
  return `cdnbarchart-${randomChartId}`;
});

const { loaderTimeoutStarted, loading: showCsvDownloadLoader, startLoaderTimeout } = useDelayedLoader(2000);
const onExportCsv = () => {
  if (isLoading.value || !isSuccess.value || !props.onExportToCsv || loaderTimeoutStarted.value) {
    return;
  }

  const promise = props.onExportToCsv(barSeries.value, chartTitle.value, {
    location: location.value,
    aggregation: aggregation.value,
    endDate,
    nbDays: nbDays?.value ?? 0,
    geohashes: geohashes.value,
    bbox: bbox.value,
    dateInterval: props.dateInterval,
    otherRequestParams: props.otherRequestParams,
    transform: props.transform,
  });

  if (promise instanceof Promise) {
    startLoaderTimeout(promise);
  }
};

const barChartConfig = {
  options: {
    plugins: {
      tooltip: {
        external: props.chartTooltipPlugin,
      },
    },
  },
};

merge(barChartConfig, { ...(chartConfig?.value || {}) });

const bottomPluginSlotId = computed(() => {
  return `bottom-plugin-slot-for-${chartId.value}`;
});
</script>

<template>
  <OnxChartContainer
    class="onx-bar-chart"
    ref="container"
    :no-data="barSeries?.labels?.length === 0"
    :loading="isLoading || isRefetching || isPending"
    :enable-csv-export="true"
    :onExportToCsv="onExportCsv"
    :title="chartTitle"
    :subtitle="chartSubtitle"
    :screenshot-legend="screenshotLegend"
    :screenshot-title="screenshotTitle"
    :screenshot-subtitle="screenshotSubtitle"
    :chart-title-tooltip="chartTitleTooltip"
    :metric-source="metricSource"
    :csv-export-loading="showCsvDownloadLoader"
    :csv-export-disabled="loaderTimeoutStarted"
  >
    <BarChart
      v-if="isSuccess"
      :chart-data="barSeries"
      :chart-id="chartId"
      :width="chartWidth"
      :height="computedHeight"
      :min="minMax?.min"
      :max="minMax ? minMax.max : props.max"
      :hideDatalabels="true"
      :stacked="stacked"
      :getTooltipTitle="getTooltipTitle"
      :orientation="props.horizontal ? 'horizontal' : 'vertical'"
      :show-secondary-value="chartTooltipShowSecondaryValue"
      :config="barChartConfig"
      :bottom-plugin-container-id="bottomPluginSlotId"
      v-bind="barChartAttrs"
    />

    <template #tools v-if="sql">
      <ViewChartSqlButton :sql="sql" :chartTitle="chartTitle" />
    </template>
    <template #bottom-plugins>
      <div :id="bottomPluginSlotId" class="onx-bar-chart__bottom-plugin-slot"></div>
    </template>
  </OnxChartContainer>
</template>

<style lang="scss">
.onx-bar-chart {
  &__bottom-plugin-slot {
    margin-bottom: 8px;
  }
}
</style>
