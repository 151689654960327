<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { type AxiosRequestConfig } from 'axios';
import { type LngLatBounds } from 'mapbox-gl';

import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { DataTransformerMetricsAPIResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';

import { Dashboards } from '@/constants/dashboards';
import { useOnxTable, Column } from '@/components/onx/table';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import { exportToCsv } from '@/utils/files';
import OnxRawTable from '@/components/onx/table/OnxRawTable.vue';
import useEndDate from '@/composables/useEndDate';
import useMetric from '@/composables/useMetric';
import useDelayedLoader from '@/composables/useDelayedLoader';
import { useMetricSQL } from '@/composables/useMetricSQL';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';

export type TableExportArgs = {
  metric: string;
  location: string | number;
  aggregation: string;
  endDate: Date;
  nbDays: number;
  otherRequestParams?: AxiosRequestConfig['params'];
  transform: DataTransformerMetricsAPIResponseCallback<any>;
};

interface Props {
  aggregation: string;
  dashboard: Dashboards;
  enabled?: boolean;
  location: string | number;
  metric: string;
  bbox?: LngLatBounds;

  onExportToCsv?: (rows: any[][], filename: string, params?: TableExportArgs) => void | Promise<void>;
  transform: DataTransformerMetricsAPIResponseCallback<any>;

  /* Table Options */
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: 'asc' | 'desc';

  /** Override the chart metric title */
  tableTitle: string;
  tableSubtitle?: string;
  exportTitle?: string;
  exportSubtitle?: string;

  tableTooltip?: string;
  otherRequestParams?: AxiosRequestConfig['params'];
}

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
});

const { aggregation, bbox, columns, enabled, location, metric, tableTitle, tableTooltip } = toRefs(props);

const { currentEndDate } = useEndDate(props.dashboard);

const {
  query: { data, isLoading, isPending, isRefetching, isSuccess },
} = useMetric<MetricStructuresEnum.Breakdown>(props.dashboard, {
  metric,
  location,
  aggregation,
  endDate: currentEndDate,
  geohashes: [],
  nbDays: 0,
  enabled,
  otherRequestParams: props.otherRequestParams,
  bbox,
});
const { sql } = useMetricSQL(data, isSuccess);

const rows = computed(() => {
  if (isLoading.value || isRefetching.value || !isSuccess.value || !data.value) {
    return [];
  }

  return props.transform(data.value.data);
});

const { clearColumnFilter, columnFilters, onClickColumn, setColumnFilter, sortColumnKey, sortDirection, sortedRows } =
  useOnxTable(rows, columns, {
    initialSortColumnKey: props.initialSortColumnKey,
    initialSortDirection: props.initialSortDirection,
  });

const { loaderTimeoutStarted, loading: showCsvDownloadLoader, startLoaderTimeout } = useDelayedLoader(2000);
const handleExportToCsv = async () => {
  if (isLoading.value || !isSuccess.value || loaderTimeoutStarted.value) {
    return;
  }

  if (props.onExportToCsv) {
    const promise = props.onExportToCsv(
      sortedRows.value.map((row) =>
        props.columns.map((column) => (column.cell ? column.cell(row) : column.value(row))),
      ),
      tableTitle.value,
      {
        metric: metric.value,
        location: location.value,
        aggregation: aggregation.value,
        endDate: currentEndDate.value,
        nbDays: 0,
        otherRequestParams: props.otherRequestParams,
        transform: props.transform,
      },
    );

    if (promise instanceof Promise) {
      startLoaderTimeout(promise);
    }

    return;
  }

  const data = [
    props.columns.map((column) => column.header),
    ...sortedRows.value.map((row) =>
      props.columns.map((column) => (column.cell ? column.cell(row) : column.value(row))),
    ),
  ];

  exportToCsv(tableTitle.value, data);
};
</script>

<template>
  <OnxChartContainer
    :title="tableTitle"
    :subtitle="tableSubtitle"
    :chart-title-tooltip="tableTooltip"
    :screenshot-title="exportTitle"
    :screenshot-subtitle="exportSubtitle"
    enable-csv-export
    :enable-image-export="false"
    @exportToCsv="handleExportToCsv"
    :loading="isLoading || isRefetching || isPending"
    :no-data="rows.length === 0"
    data-test-id="onx-table__root"
    :csv-export-loading="showCsvDownloadLoader"
    :csv-export-disabled="loaderTimeoutStarted"
  >
    <div class="onx-table-wrapper">
      <OnxRawTable
        :rows="sortedRows"
        :columns="columns"
        :column-filters="columnFilters"
        :sort-column-key="sortColumnKey"
        :sort-direction="sortDirection"
        @column:click="onClickColumn"
        @column:clear-filter="clearColumnFilter"
        @column:set-filter="setColumnFilter"
      />
    </div>
    <template #tools v-if="sql">
      <ViewChartSqlButton :sql="sql" :chartTitle="tableTitle" />
    </template>
  </OnxChartContainer>
</template>

<style lang="scss">
.onx-table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-height: 310px;
  overflow-y: scroll;
}

.onx-table__export {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 8px;
}
</style>
