<script setup lang="ts">
import { FieldGroup, Flatpickr } from '@/components/menu';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useEndDate from '@/composables/useEndDate';

const queryParams = useSpotlightQueryParams();

const dashboard = useCurrentDashboardName();
const { currentEndDate, parsedFirstDateAvailable, parsedLastDateAvailable } = useEndDate(dashboard.value);
</script>

<template>
  <FieldGroup label="End Date">
    <Flatpickr
      :min="parsedFirstDateAvailable"
      :max="parsedLastDateAvailable"
      :date="currentEndDate"
      @selected="queryParams.date.onChange"
    />
  </FieldGroup>
</template>
