<script setup lang="ts">
import { capitalize } from 'lodash';
import { computed } from 'vue';
import OnxTag from '../tags/OnxTag.vue';
import OperatorAvatar from '@/components/OperatorAvatar.vue';
import useLocations from '@/composables/useLocations';
import useNetworkOperatorsWithHomeNetwork from '@/composables/useNetworkOperatorsWithHomeNetwork';
import { SpotlightPulse } from '@/composables/useSpotlightPulse';
import { METRIC_TYPE_NAMES, OS_GEOCODINGS } from '@/constants/constants';
import { Dashboards } from '@/constants/dashboards';
import ROUTES from '@/constants/routes';
import { MetricSubtypeTitles } from '@/focus/constants/titles';
import router from '@/router';
import { Location } from '@/types/Location';
import { Operator } from '@/types/Operator';
import { AGGREGATIONS } from '@/constants/constants';
import { getLongDate } from '@/utils/date';

type Props = {
  item: SpotlightPulse;

  /** Whether to highlight or not. If true, the item is visually highlighted. */
  isNew?: boolean;
};

/**
 * Each Pulse item has a list of regions and cities associated with this notification. Each of those
 * is a clickable tag, so this stores the info needed to navigate to the correct page when clicking.
 * See @createSpotlightPulseNavigationTarget
 */
type SpotlightPulseNavigationTarget = {
  locationID: number;
  countryID: number;
  geocoding: number;

  /** Visible label */
  label: string;

  /** For CSS class */
  className: string;
};

const props = withDefaults(defineProps<Props>(), {
  isNew: true,
});

const emit = defineEmits(['markRead', 'navigate']);

const locations = useLocations(Dashboards.Spotlight);
const location_id = computed<number>(() => props.item.location_id);
const operators = useNetworkOperatorsWithHomeNetwork(Dashboards.Spotlight, location_id);
const operator = computed<Operator | undefined>(() =>
  operators.value.find((op) => op.canonical_network_id === props.item.canonical_network_id),
);

const connectionCategoryLabel = computed<string>(() => {
  const connectionCategoryValue = props.item.connectivity_type;
  if (METRIC_TYPE_NAMES.hasOwnProperty(connectionCategoryValue)) {
    return METRIC_TYPE_NAMES[connectionCategoryValue as keyof typeof METRIC_TYPE_NAMES];
  } else {
    return capitalize(connectionCategoryValue);
  }
});

const countryLocation = computed<Location | undefined>(() => {
  const countryID = props.item.location_id;
  if (locations.locationsByID.hasOwnProperty(countryID)) {
    return locations.locationsByID[countryID];
  } else {
    return undefined;
  }
});

const metricLabel = computed<string>(() => {
  const subtype = props.item.metric_subtype;
  if (MetricSubtypeTitles.hasOwnProperty(subtype)) {
    return MetricSubtypeTitles[subtype as keyof typeof MetricSubtypeTitles];
  } else {
    return capitalize(subtype);
  }
});

const aggregationLabel = computed(() => {
  const aggregationTypeValue = props.item.aggregation_type;
  const foundAggregation = AGGREGATIONS.find((agg) => agg.value === aggregationTypeValue);
  if (foundAggregation) {
    return foundAggregation.label;
  } else {
    return aggregationTypeValue;
  }
});

const isRead = computed<boolean>(() => props.item.is_read || !props.isNew);

const createSpotlightPulseNavigationTarget = (
  locationID: number,
  geocoding: number,
  className: string,
): SpotlightPulseNavigationTarget => {
  return {
    locationID,
    countryID: props.item.location_id,
    geocoding,
    label: locations.locationsByID[locationID].name,
    className,
  };
};

const regionTags = computed<SpotlightPulseNavigationTarget[]>(() => {
  if (Array.isArray(props.item.region_contribution_locations)) {
    return props.item.region_contribution_locations.map((locationID) => {
      return createSpotlightPulseNavigationTarget(locationID, OS_GEOCODINGS.regions, 'location-region');
    });
  } else {
    return [];
  }
});

const cityTags = computed<SpotlightPulseNavigationTarget[]>(() => {
  if (Array.isArray(props.item.city_contribution_locations)) {
    return props.item.city_contribution_locations.map((locationID: number) => {
      return createSpotlightPulseNavigationTarget(locationID, OS_GEOCODINGS.cities, 'location-metro-area');
    });
  } else {
    return [];
  }
});

const shouldShowCountryTag = computed(() => {
  return cityTags.value.length === 0 && regionTags.value.length === 0;
});
const countryTarget = computed(() => {
  return createSpotlightPulseNavigationTarget(props.item.location_id, OS_GEOCODINGS.countries, 'location-country');
});

const navigateToSpotlightByConnectionCategory = () => {
  if (!countryLocation.value || !countryTarget.value) {
    return;
  }

  router.push({
    name: ROUTES.CompetitiveConnectionCategory,
    query: {
      agg: props.item.aggregation_type,
      compareTo: router.currentRoute.value?.query?.compareTo || '90days',
      country: countryLocation.value.iso3,
      countryid: countryTarget.value.countryID,
      date: props.item.report_date,
      geocoding: countryTarget.value.geocoding,
      location: props.item.location_id,
      metric: props.item.metric_subtype,
      network: 'all',
    },
  });
  emit('navigate');
  emit('markRead');
};

const navigateToSpotlightByGeography = (target: SpotlightPulseNavigationTarget) => {
  if (!countryLocation.value) {
    return;
  }

  router.push({
    name: ROUTES.CompetitiveDetails,
    query: {
      agg: props.item.aggregation_type,
      compareTo: router.currentRoute.value?.query?.compareTo || '90days',
      country: countryLocation.value.iso3,
      countryid: target.countryID,
      date: props.item.report_date,
      geocoding: target.geocoding,
      location: target.locationID,
      metric: `${props.item.metric_subtype}_${props.item.connectivity_type}`,
      network: 'all',
    },
  });
  emit('navigate');
  emit('markRead');
};
</script>
<template>
  <div class="spotlight-pulse__item" :class="{ is_read: isRead }">
    <div class="spotlight-pulse__item__icon">
      <OperatorAvatar
        v-if="operator"
        :background-color="`#${operator.hex_color}`"
        :name="operator.name_mapped"
        :background-style="operator.is_mvno ? 'outline' : 'fill'"
      />
    </div>
    <div class="spoglight-pulse__item__title">{{ item.reason }}</div>
    <div class="spotlight-pulse__item__tags">
      <OnxTag class="connection-category" @click="navigateToSpotlightByConnectionCategory()">
        {{ metricLabel }} {{ connectionCategoryLabel }}
      </OnxTag>
      <OnxTag
        v-if="shouldShowCountryTag"
        :class="countryTarget.className"
        @click="navigateToSpotlightByGeography(countryTarget)"
      >
        {{ countryTarget.label }}
      </OnxTag>
      <OnxTag
        v-for="target in regionTags"
        :key="target.locationID"
        :class="target.className"
        @click="navigateToSpotlightByGeography(target)"
      >
        {{ target.label }}
      </OnxTag>
      <OnxTag
        v-for="target in cityTags"
        :key="target.locationID"
        :class="target.className"
        @click="navigateToSpotlightByGeography(target)"
      >
        {{ target.label }}
      </OnxTag>
    </div>
    <div class="spotlight-pulse__item__info">
      <div class="spotlight-pulse__item__date">{{ getLongDate(props.item.report_date) }}</div>
      &#x2022;
      <div class="spotlight-pulse__item__aggregation">{{ aggregationLabel }}</div>
    </div>
    <button class="spotlight-pulse__item__mark-read" @click="$emit('markRead')" v-if="!isRead">Mark as read</button>
  </div>
</template>

<style lang="scss" scoped>
.spotlight-pulse__item {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-areas:
    'icon title'
    'icon tags'
    'icon info';
  grid-row-gap: 1rem;
  background-color: var(--teal-100);
  transition: background-color 0.1s ease;
  position: relative;

  &.is_read {
    background-color: white;
  }

  &__icon {
    grid-area: icon;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    grid-area: title;
    font-size: 1.5rem;
  }

  &__tags {
    grid-area: tags;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;

    > .connection-category {
      cursor: pointer;
      color: var(--charcoal-500);
      background-color: var(--marigold-300);

      &:hover {
        background-color: var(--marigold-500);
        color: white;
      }
    }

    > .location-country {
      cursor: pointer;
      color: white;
      background-color: var(--pink-300);

      &:hover {
        background-color: var(--pink-500);
        color: white;
      }
    }

    > .location-region {
      cursor: pointer;
      color: var(--charcoal-500);
      background-color: var(--teal-300);

      &:hover {
        background-color: var(--teal-600);
        color: white;
      }
    }

    > .location-metro-area {
      cursor: pointer;
      background-color: var(--charcoal-300);
      color: white;

      &:hover {
        background-color: var(--charcoal-500);
        color: white;
      }
    }
  }

  &__info {
    grid-area: info;
    display: flex;
    gap: 0.5rem;
    font-size: small;
    color: #999999;
  }

  &__mark-read {
    background: none;
    border: none;
    bottom: 1rem;
    color: var(--charcoal-300);
    cursor: pointer;
    display: none;
    font-size: small;
    outline: none;
    position: absolute;
    right: 1rem;

    &:hover {
      background: var(--light);
    }

    &:active {
      background: var(--charcoal-100);
    }
  }

  &:hover .spotlight-pulse__item__mark-read {
    display: initial;
  }
}
</style>
