<template>
  <div class="Overview">
    <CompetitiveOverviewLayout :number-of-tiles="competitiveTiles.length">
      <template #header="headerProps">
        <OnxSpotlightHeaderNav>
          <template #actions>
            <OnxToggle v-model="showScoreChange" label="Show score change" />

            <OnxVerticalDivider
              :spacing="matches.desktop.value ? 16 : 8"
              class="onx-navigation-header__vertical-divider"
            />

            <HtmlImageExport
              v-show="matches.desktop.value"
              ref="htmlImageExportRef"
              :reference="headerProps.htmlImageExportData.spotlightParent.ref"
              :height="headerProps.htmlImageExportData.exportHeight"
              :title="exportTitle"
            />

            <VDropdown
              v-if="matches.mobile.value && !matches.desktop.value"
              :distance="6"
              class="onx-navigation-header__settings"
            >
              <span>
                <MenuDotsIcon button />
              </span>

              <template #popper>
                <OnxPaper :depth="3">
                  <OnxList>
                    <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                      <OnxListItemText size="sm"> Save as image </OnxListItemText>
                      <template #right>
                        <DownloadFileIcon />
                      </template>
                    </OnxListItem>
                  </OnxList>
                </OnxPaper>
              </template>
            </VDropdown>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #competitiveTiles>
        <LoaderGrid v-if="loadingMetrics.length" />

        <template v-else>
          <CompetitiveTile
            v-for="tile in competitiveTiles"
            :key="tile.metricKind"
            :metric-type="tile.metricType"
            :metric-kind="tile.metricKind"
            :metric-unit="tile.metricUnit"
            :metric-category="tile.metricCategory"
            :bigger-better="tile.biggerBetter"
            :title="tile.title"
            :ranks="tile.ranks"
            :options="tile.options"
            :granularity="tile.granularity"
            :show-score-change="showScoreChange"
            :is-loading="tile.loading"
            @navigate="navigate(tile.metric)"
          >
            <template #title>
              <CustomTooltip :message="tooltips[`spotlight_${tile.metricKind}_${tile.metricType}`]" placement="top">
                <OnxMetricIcon :metric-kind="tile.metricKind" class="Card__header__metric-icon" />
              </CustomTooltip>

              <div class="Card__headerTitle">
                <OnxHeadline as="h4">
                  {{ tile.title }}

                  <AwardIndicator v-if="tile.isAwardMetric" :userGroup="userGroup" />
                </OnxHeadline>
              </div>
            </template>

            <template #actions>
              <OnxButton
                variant="tertiary"
                size="sm"
                :data-test-id="`spotlight-overview_${tile.metricKind}-box_by-geography-btn`"
                @click="navigate(tile.metric)"
              >
                <span>By geography</span>
                <ChevronRightIcon small />
              </OnxButton>

              <OnxButton
                variant="tertiary"
                size="sm"
                :data-test-id="`spotlight-overview_${tile.metricKind}-box_by-connection-btn`"
                @click="navigateToConnection(tile.metric, tile)"
                :disabled="tile.byConnectionDisabled"
              >
                <span>By connection</span>
                <ChevronRightIcon small />
              </OnxButton>
            </template>
          </CompetitiveTile>
        </template>
      </template>
    </CompetitiveOverviewLayout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompetitiveTile from '@/components/comparison/CompetitiveTile';
import { CompetitiveOverviewLayout } from '@/components/competitive';
import HtmlImageExport from '@/components/HtmlImageExport';
import LoaderGrid from '@/components/LoaderGrid';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useMetricBoxData from '@/components/onx/composables/useMetricBoxData';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon';
import OnxButton from '@/components/onx/OnxButton';
import { OnxList, OnxListItem, OnxListItemText } from '@/components/onx/OnxList';
import OnxPaper from '@/components/onx/OnxPaper';
import OnxToggle from '@/components/onx/OnxToggle';
import OnxVerticalDivider from '@/components/onx/OnxVerticalDivider';
import OnxHeadline from '@/components/onx/typography/OnxHeadline';
import { PILLS, LATEST_STRING, DEFAULT_USER_GROUP, OS_GEOCODINGS } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import router from '@/router';
import { getLongDate } from '@/utils/date';
import { isAwardMetric } from '@/utils/metrics';
import AwardIndicator from '@/components/competitive/AwardIndicator';
import { CustomTooltip } from '@/components/tooltip';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';

export default {
  name: 'CompetitiveOverview',
  components: {
    AwardIndicator,
    ChevronRightIcon,
    CompetitiveOverviewLayout,
    CompetitiveTile,
    CustomTooltip,
    DownloadFileIcon,
    HtmlImageExport,
    LoaderGrid,
    MenuDotsIcon,
    OnxButton,
    OnxHeadline,
    OnxList,
    OnxListItem,
    OnxListItemText,
    OnxMetricIcon,
    OnxPaper,
    OnxSpotlightHeaderNav,
    OnxToggle,
    OnxVerticalDivider,
  },
  beforeRouteUpdate(to, from, next) {
    if (
      to.query.location !== this.queryParams.location.toValue() ||
      to.query.date !== this.queryParams.date.toValue()
    ) {
      this.fetchData({
        location: to.query.location,
        date: to.query.date,
      });
    }

    next();
  },
  setup() {
    const matches = useBreakpoints();
    const { createMetricBoxData } = useMetricBoxData('overviewStats');
    const queryParams = useSpotlightQueryParams();

    return {
      matches,
      createMetricBoxData,
      queryParams,
    };
  },
  data() {
    return {
      pills: PILLS,
      showScoreChange: false,
      displayModal: false,
      tooltips: TOOLTIP_MESSAGES,
    };
  },
  computed: {
    ...mapGetters(['locations', 'metrics', 'overviewStats', 'user', 'dashboardInfo']),
    ...mapGetters({
      availableNetworks: 'charts/selectedNetworkOperators',
      lastDateAvailable: 'auth/getLastDateAvailable',
      orderValues: 'competitive/getOrderedValues',
      defaultMetricType: 'competitive/defaultMetricType',
      currentLocation: 'location/currentLocation',
      currentCountry: 'location/currentCountry',
      userGeocodings: 'location/groupings',
      loadingMetrics: 'overviewStats/loadingMetrics',
      userGroup: 'competitive/userGroup',
    }),
    availableNetworkIds() {
      return this.availableNetworks.map((network) => network.canonical_network_id);
    },
    dates() {
      const compareToKey = this.queryParams.compareTo.toValue();

      const reference = this.overviewStats.find((a) => a.previous[compareToKey]);
      if (!reference) return {};

      return {
        current: getLongDate(reference.date),
        previous: getLongDate(reference.previous[compareToKey].date),
      };
    },
    exportTitle() {
      return `Overview ${this.currentLocation.name} ${this.lastDateAvailable.substring(0, 10)}`;
    },
    competitiveTiles() {
      if (!this.userGroup) {
        this.setUserGroup(DEFAULT_USER_GROUP);
      }
      return this.pills
        .filter((p) => {
          return this.metrics.find((m) => m.key === p.metric) && p.visible;
        })
        .map((pill) => {
          const selectedMetric = this.metrics.find((m) => m.key === pill.metric) || {};

          const ranksSource = this.orderValues(this.overviewStats, selectedMetric).filter((rank) => {
            return this.availableNetworkIds.includes(rank.canonical_network_id);
          });

          return this.createMetricBoxData(ranksSource, {
            ...selectedMetric,
            isAwardMetric: isAwardMetric({
              ...selectedMetric,
              user_group: this.userGroup,
            }),
          });
        });
    },
  },
  watch: {
    defaultMetricType() {
      // Check if user group still applies
      this.setDefaultMetricType();
      this.fetchData({
        location: this.queryParams.location.toValue(),
        date: this.queryParams.date.toValue(),
      }).then(() => this.trackRoute('competitive'));
    },
    userGroup() {
      this.setDefaultMetricType();
      this.fetchData({
        location: this.queryParams.location.toValue(),
        date: this.queryParams.date.toValue(),
      }).then(() => this.trackRoute('competitive'));
    },
  },
  mounted() {
    this.setDefaultMetricType();
    this.fetchData({
      location: this.queryParams.location.toValue(),
      date: this.queryParams.date.toValue(),
    });
  },
  methods: {
    ...mapActions(['setOverview', 'trackRoute', 'setUserGroup']),
    fetchData(args) {
      const date = args.date === LATEST_STRING ? this.dashboardInfo.last_date_available : args.date;
      return this.setOverview({
        location: args.location,
        date,
        metricString: args.metricString || this.pills.map((pill) => pill.metric).toString(),
      });
    },
    navigate(metric) {
      let geocoding;
      const countryHasRegions = this.userGeocodings.some((g) => g.id === OS_GEOCODINGS.regions);
      const currentLocationIsCountry = parseInt(this.currentLocation.granularityId, 10) === OS_GEOCODINGS.countries;

      if (currentLocationIsCountry && countryHasRegions) {
        geocoding = OS_GEOCODINGS.regions;
      } else {
        geocoding = this.currentLocation.granularityId;
      }

      router.push({
        name: ROUTES.CompetitiveDetails,
        query: {
          location: this.queryParams.location.toValue(),
          network: 'all',
          countryid: this.currentCountry.key,
          geocoding,
          metric: metric,
          agg: '90days',
          date:
            this.queryParams.date.toValue() === LATEST_STRING
              ? this.dashboardInfo.last_date_available
              : this.queryParams.date.toValue(),
          compareTo: this.queryParams.compareTo.toValue(),
        },
      });
    },
    navigateToConnection(metric) {
      router.push({
        name: ROUTES.CompetitiveConnectionCategory,
        query: {
          location: this.queryParams.location.toValue(),
          network: 'all',
          countryid: this.currentCountry.key,
          geocoding: this.currentLocation.granularityId,
          metric: metric.split('_')[0],
          agg: '90days',
          date:
            this.queryParams.date.toValue() === LATEST_STRING
              ? this.dashboardInfo.last_date_available
              : this.queryParams.date.toValue(),
          compareTo: this.queryParams.compareTo.toValue(),
        },
      });
    },
    setDefaultMetricType() {
      this.pills = this.pills.map((pill) => {
        pill.visible = false;

        const targetMetricType = this.defaultMetricType;

        const metricForType = this.metrics.find(
          (metric) =>
            metric.type === targetMetricType && metric.kind === pill.key && metric.subcategory === this.userGroup,
        );

        if (metricForType) {
          pill = {
            key: metricForType.kind,
            metric: metricForType.key,
            visible: true,
          };
        }

        return pill;
      });
    },
    onHtmlImageExportListItemClick() {
      this.$refs.htmlImageExportRef.print();
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;

.race-chart {
  position: relative;

  &__camera {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Overview {
  position: relative;
  min-height: 100%;

  .onx-metric-box__content .RankingTable {
    cursor: pointer;
  }

  .onx-headline {
    display: flex;
    align-items: center;
  }
}
</style>
