import transformCongestionRANTechCellByOperator from '../data-transformers/transformCongestionRANTechCellCountByOperator';
import { transformCongestionCategoryByOperator } from '../data-transformers/transformCongestionBreakdownStructureByOperator';
import { ChartMetricDefinition, InitialSortDirection } from '@/chart-metric-definitions/MetricDefinition';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';

import { METRIC_TYPES } from '@/constants/constants';
import { congestionUnderutilizedCellsColumns } from '@/360/base-station/table-columns/congestionUnderutilizedCellsColumns';
import { congestionMostCongestedCellsColumns } from '@/360/base-station/table-columns/congestionMostCongestedCellsColumns';
import { congestionAllCellsColumns } from '@/360/base-station/table-columns/congestionAllCellsColumns';

import { prepareCongestionUnderutilizedCellsForTable } from '@/chart-metric-definitions/data-transformers/prepareCongestionUnderutilizedCellsForTable';
import { prepareCongestionMostCongestedCellsForTable } from '@/chart-metric-definitions/data-transformers/prepareCongestionMostCongestedCellsForTable';
import { prepareCongestionAllCellsForTable } from '@/chart-metric-definitions/data-transformers/prepareCongestionAllCellsForTable';

import exportCongestionUnderutilizedCapacityScoreToCsv from '@/360/csv-export-overrides/exportCongestionUnderutilizedCapacityScoreToCsv';
import exportCongestionMostCongestedCellsToCsv from '@/360/csv-export-overrides/exportCongestionMostCongestedCellsToCsv';
import transformCongestionHourlyPerOperator from '@/chart-metric-definitions/data-transformers/transformCongestionHourlyPerOperator';
import transformSimpleStructureSubmetricsByOperator from '@/chart-metric-definitions/data-transformers/transformSimpleStructureSubmetricsByOperator';
import transformSimpleStructurePerOperatorForGaugeChart from '@/chart-metric-definitions/data-transformers/transformSimpleStructurePerOperatorForGauge';
import exportDatasetToCsv from '@/utils/exportDatasetToCsv';
import congestionHourlyChartTooltip from '@/chart-metric-definitions/tooltips/congestionHourlyChartTooltip';
import exportCongestionAllCellsTableToCsv from '@/360/csv-export-overrides/exportCongestionAllCellsTableToCsv';
import simpleStructureSubmetricByOperatorTooltip from '@/chart-metric-definitions/tooltips/simpleStructureSubmetricByOperatorTooltip';

const onx360BaseStationSiteMapperChartMetricDefinitions: Record<
  string,
  Array<ChartMetricDefinition | ChartMetricDefinition[]>
> = {
  nonPeriodByOperator: [
    {
      connectionCategory: METRIC_TYPES.Overall,
      chartType: ChartTypesEnum.DatasetTable,
      columns: congestionUnderutilizedCellsColumns,
      dataset: 'onx360_congestion_underutilized_capacity_score_table',
      initialSortColumnKey: 'capacity_score',
      initialSortDirection: InitialSortDirection.Desc,
      transformData: prepareCongestionUnderutilizedCellsForTable,
      onExportCsv: exportCongestionUnderutilizedCapacityScoreToCsv,
      chartAttrs: {
        chartTitlePlaceholders: 'Underutilized Cells',
      },
    },
    {
      connectionCategory: METRIC_TYPES.Overall,
      chartType: ChartTypesEnum.DatasetTable,
      columns: congestionMostCongestedCellsColumns,
      dataset: 'onx360_congestion_most_congested_cell_table',
      initialSortColumnKey: 'congestion_score',
      initialSortDirection: InitialSortDirection.Desc,
      transformData: prepareCongestionMostCongestedCellsForTable,
      onExportCsv: exportCongestionMostCongestedCellsToCsv,
      chartAttrs: {
        chartTitlePlaceholders: 'Most Congested Cells',
      },
    },
    {
      connectionCategory: METRIC_TYPES.Overall,
      chartType: ChartTypesEnum.DatasetTable,
      columns: congestionAllCellsColumns,
      dataset: 'onx360_congestion_all_cell_table',
      initialSortColumnKey: 'uplink_score',
      initialSortDirection: InitialSortDirection.Desc,
      transformData: prepareCongestionAllCellsForTable,
      onExportCsv: exportCongestionAllCellsTableToCsv,
      chartAttrs: {
        chartTitlePlaceholders: 'All Cells',
      },
    },
  ],
  periodByOperator: [
    {
      metricSubtype: MetricSubtypes.CongestionRANCellCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: false,
        nbDays: 0,
        chartTitlePlaceholders: 'Number of Cells by RAN Technology',
        chartConfig: {
          options: {
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'Number of Cells',
                },
              },
            },
          },
        },
      },
      transformData: transformCongestionRANTechCellByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['RAN Technology', 'Date', 'Operator', 'Number of Cells'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, date, datum[dataset.labelAxis], datum[dataset.dataAxis]];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.CongestionCongestedCells,
      connectionCategory: METRIC_TYPES.Overall,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        chartTitlePlaceholders: 'Total Cells',
        horizontal: true,
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Total Cells', 'Date'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, datum[dataset.dataAxis], date];
        },
      }),
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
    },
    {
      metricSubtype: MetricSubtypes.CongestionCategoryDownload,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Median Download Throughput (Mbps)',
        stacked: false,
        barChartAttrs: {
          showLegend: true,
        },
      },
      transformData: transformCongestionCategoryByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Date', 'Operator', 'Congestion Category', 'Download Throughput'],
        getRow: (datum, dataset, date) => {
          return [date, datum[dataset.labelAxis], dataset.label, datum[dataset.dataAxis]];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.CongestionCategoryUpload,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Median Upload Throughput (Mbps)',
        stacked: false,
        barChartAttrs: {
          showLegend: true,
        },
      },
      transformData: transformCongestionCategoryByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Date', 'Operator', 'Congestion Category', 'Upload Throughput'],
        getRow: (datum, dataset, date) => {
          return [date, datum[dataset.labelAxis], dataset.label, datum[dataset.dataAxis]];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.CongestionCategoryPacketLossDiscard,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Packet Loss / Discard (%)',
        stacked: false,
        barChartAttrs: {
          showLegend: true,
        },
      },
      transformData: transformCongestionCategoryByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Date', 'Operator', 'Congestion Category', 'Packet Loss / Discard'],
        getRow: (datum, dataset, date) => {
          return [date, datum[dataset.labelAxis], dataset.label, datum[dataset.dataAxis]];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.CongestionCellBreakdown,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Cell Breakdown',
        stacked: false,
        barChartAttrs: {
          showLegend: true,
        },
      },
      transformData: transformCongestionCategoryByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Date', 'Operator', 'Congestion Category', 'Cell Breakdown'],
        getRow: (datum, dataset, date) => {
          return [date, datum[dataset.labelAxis], dataset.label, datum[dataset.dataAxis]];
        },
      }),
    },
  ],
  periodForEachOperator: [
    {
      metricSubtype: MetricSubtypes.CongestionHourlyJitter,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        chartTitlePlaceholders: 'Hourly Jitter for :operator (ms)',
        horizontal: false,
        nbDays: 0,
        chartConfig: {
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Hours',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Jitter (ms)',
                },
              },
            },
          },
        },
      },
      transformData: transformCongestionHourlyPerOperator,
      get tooltipPlugin() {
        return congestionHourlyChartTooltip({
          horizontal: !!this.chartAttrs?.horizontal,
          tooltipTitleOverride: 'Jitter',
        });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Date', 'Hour', 'Jitter'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, date, datum[dataset.labelAxis], datum[dataset.dataAxis]];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.CongestionHourlyPacketLossDiscard,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        chartTitlePlaceholders: 'Hourly Packet Loss / Discard for :operator (%)',
        horizontal: false,
        nbDays: 0,
        chartConfig: {
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Hours',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Packet Loss / Discard (%)',
                },
              },
            },
          },
        },
      },
      transformData: transformCongestionHourlyPerOperator,
      get tooltipPlugin() {
        return congestionHourlyChartTooltip({
          horizontal: !!this.chartAttrs?.horizontal,
          tooltipTitleOverride: 'Packet Loss / Discard',
        });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Date', 'Hour', 'Packet Loss / Discard'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, date, datum[dataset.labelAxis], datum[dataset.dataAxis]];
        },
      }),
    },
    [
      {
        metricSubtype: MetricSubtypes.CongestionRANDownload,
        chartType: ChartTypesEnum.Gauge,
        connectionCategory: METRIC_TYPES.Overall,
        chartAttrs: {
          chartTitlePlaceholders: 'RAN DL Throughput for :operator (Mbps)',
        },
        chartContainerClass: 'gauge-chart-cell',
        // this translates visually to 0-10, 10-25, 25-50 in the gauge chart
        // in the gauge chart, these define how big the segments are in the chart
        // the labels will display 0, 10, 25 and 50 because label[n+1] is obtained from the data[n] + data[n+1]
        transformData: transformSimpleStructurePerOperatorForGaugeChart([10, 15, 25]),
      },
      {
        metricSubtype: MetricSubtypes.CongestionNonRANDownload,
        chartType: ChartTypesEnum.Gauge,
        connectionCategory: METRIC_TYPES.Overall,
        chartAttrs: {
          chartTitlePlaceholders: 'Non-RAN DL Throughput for :operator (Mbps)',
        },
        chartContainerClass: 'gauge-chart-cell',
        transformData: transformSimpleStructurePerOperatorForGaugeChart([10, 15, 25]),
      },
      {
        metricSubtype: MetricSubtypes.CongestionAtRiskDownload,
        chartType: ChartTypesEnum.Gauge,
        connectionCategory: METRIC_TYPES.Overall,
        chartAttrs: {
          chartTitlePlaceholders: 'At Risk DL Throughput for :operator (Mbps)',
        },
        chartContainerClass: 'gauge-chart-cell',
        transformData: transformSimpleStructurePerOperatorForGaugeChart([10, 15, 25]),
      },
      {
        metricSubtype: MetricSubtypes.CongestionUnderutilizedDownload,
        chartType: ChartTypesEnum.Gauge,
        connectionCategory: METRIC_TYPES.Overall,
        chartAttrs: {
          chartTitlePlaceholders: 'Underutilized DL Throughput for :operator (Mbps)',
        },
        chartContainerClass: 'gauge-chart-cell',
        transformData: transformSimpleStructurePerOperatorForGaugeChart([10, 15, 25]),
      },
    ],
  ],
};

export default onx360BaseStationSiteMapperChartMetricDefinitions;
