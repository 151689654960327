<script setup lang="ts">
import { ComponentPublicInstance, computed, ref, toRefs, withDefaults } from 'vue';
import { ChartData } from 'chart.js';

import type { BinnedMetric } from '@/types/MetricStructures';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import { BarChart } from '@/components/visual';
import useFilters from '@/composables/useFilters';
import useMetricTitleLabels from '@/composables/useMetricTitleLabels';
import { usePercentileControl } from '@/composables/usePercentileControl';
import { Dashboards } from '@/constants/dashboards';
import { getDatapointsByIndex, getTooltipState } from '@/utils/charts';
import { getBinnedDistribution } from '@/utils/viewHelpers';
import useMetricSource from '@/composables/useMetricSource';
import OnxPercentileControl from '@/components/visual/tools/OnxPercentileControl.vue';
import { OnxChartBaseProps } from '@/components/onx/charts/OnxChartBaseProps';
import { useMetricSQL } from '@/composables/useMetricSQL';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';
import naiveId from '@/utils/naiveId';
import { MetricResponse } from '@/types/MetricResponse';

export type Props = OnxChartBaseProps & {
  geohashes: string[];
  enabled?: boolean;
  transform?: (response: any) => BinnedMetric[];
  hidden?: boolean;
  showCumulativeLine?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  enabled: undefined,
  hidden: false,
});

const {
  chartSubtitle,
  chartTitle,
  chartTitleTooltip,
  endDate,
  hidden,
  metric,
  screenshotSubtitle,
  screenshotTitle,
  showCumulativeLine,
  transform,
} = toRefs(props);
const { operators } = useFilters(Dashboards.Focus);
const { getPercentileCutoff, percentile } = usePercentileControl();
const { titleLabels } = useMetricTitleLabels(Dashboards.Focus, metric);

const container = ref<ComponentPublicInstance | null>(null);
const chartWidth = computed(() => {
  if (container.value) {
    return container.value.$el.offsetWidth;
  }

  return 0;
});

const randomChartId = naiveId();
const chartId = computed(() => {
  return `dist-${metric.value}-${randomChartId}`;
});

const query = props.queryFn({ nbDays: 0, endDate });
const {
  query: { data: response, isLoading, isPending, isRefetching, isSuccess },
} = query;

const { metricSource } = useMetricSource(response);
const { sql } = useMetricSQL(response, isSuccess);

const getCumulativeLines = (bins: any[]) => {
  const cumulativeLines = bins.map((bin) => {
    const dataset = {
      ...bin,
      type: 'line',
      data: [0],
      order: 1,
      pointRadius: 0,
      pointHitRadius: 0,
      borderColor: bin.color,
      borderWidth: 2,
      // xAxisID for the line chart should be different because the bar chart x scale comes with padding at the start and end
      xAxisID: 'xAxisCumulative',
      yAxisID: 'yAxisCumulative',
    };

    bin.data.forEach((dataPoint: number, index: number) => {
      const item = Math.round((dataset.data[index] + dataPoint) * 100) / 100;
      dataset.data.push(item < 100 ? item : 100);
    });

    return dataset;
  });

  return cumulativeLines;
};

const barSeries = computed((): ChartData => {
  if (isSuccess.value) {
    const data = response.value?.data as MetricResponse<any>;

    if (data?.operators) {
      const filteredOperators = Object.values(data.operators).filter((operator) => {
        if (operators.value.length === 0) {
          return true;
        }

        return operators.value?.includes(operator.canonical_network_id.toString());
      });

      let groupedBars: { data: any[]; label: any[] };

      if (transform?.value) {
        groupedBars = getBinnedDistribution(transform.value(data.results), filteredOperators);
      } else {
        groupedBars = getBinnedDistribution(data.results, filteredOperators);
      }

      const cumulativeLines = showCumulativeLine?.value ? getCumulativeLines(groupedBars.data) : [];
      if (percentile.value > 0 && percentile.value < 100) {
        const allSeriesAddedTogether: number[] = groupedBars.label.map((_: any, index: number) => {
          return groupedBars.data.reduce((acc, curr) => {
            // for each series, add the nth items together
            return acc + curr.data[index];
          }, 0);
        });
        const cutoff = getPercentileCutoff(allSeriesAddedTogether, percentile.value);

        return {
          labels: groupedBars.label.slice(0, cutoff),
          datasets: [
            ...cumulativeLines,
            ...groupedBars.data.map((datum) => ({
              ...datum,
              type: 'bar',
              data: datum.data.slice(0, cutoff),
              order: 2,
              yAxisID: 'y',
              xAxisID: 'x',
            })),
          ],
        };
      }

      return {
        labels: groupedBars.label,
        datasets: [...cumulativeLines, ...groupedBars.data.map((d) => ({ ...d, order: 1, yAxisID: 'y' }))],
      };
    }
  }

  return { labels: [], datasets: [] };
});

const setTooltipData = (context: any, tooltip: any, chartData: any) => {
  const tt = context.tooltip;
  const tooltipState = getTooltipState(context.chart, tt);

  const datapoints = getDatapointsByIndex(chartData.value.datasets, chartData.value.labels.indexOf(tt.title[0]));

  const lineDatapointsByName = datapoints.reduce((acc: Record<string, any>, d: any) => {
    if (d.type !== 'line') {
      return acc;
    }

    acc[d.label] = d;

    return acc;
  }, {});

  const barDatapoints = (datapoints || [])
    .filter((d: any) => d.type !== 'line')
    .map((d: any) => ({ ...d, secondaryValue: lineDatapointsByName[d.label].data }));

  if (datapoints.length) {
    const metricUnit = titleLabels.value.metricUnit != '0 - 100' ? titleLabels.value.metricUnit || '' : '';

    const titleIndex = chartData.value.labels.indexOf(tt.title[0]);
    const siblingTitle = chartData.value.labels[titleIndex + 1] || chartData.value.labels[titleIndex - 1];

    const lowerLimit = Number(tt.title[0]);
    const scale = Math.abs(Number(siblingTitle) - lowerLimit);

    const upperLimit = siblingTitle ? Math.floor((lowerLimit + scale - 0.001) * 100) / 100 : '';

    tooltip.value = {
      ...tooltip.value,
      ...tooltipState,
      datapoints: barDatapoints,
      title: `${lowerLimit.toFixed(2)}${upperLimit ? ' - ' + upperLimit : ''} ${metricUnit} bin`,
      secondaryTitle: 'Cumulative',
    };
  } else {
    tooltip.value.display = false;
  }
};

const screenshotLegend = computed(() => {
  if (!barSeries.value || barSeries.value.datasets.length === 0) {
    return [];
  }

  // remove the cumulative lines from the legend
  return barSeries.value.datasets
    .filter((dataset) => {
      return dataset.type !== 'line';
    })
    .map((dataset: any) => {
      return (
        dataset.meta?.imageExportLegend || {
          label: dataset.label,
          color: dataset.backgroundColor,
        }
      );
    });
});
</script>

<template>
  <OnxChartContainer
    class="onx-dist-chart"
    ref="container"
    :loading="isLoading || isRefetching || isPending"
    :no-data="barSeries.datasets.length === 0"
    :hidden="hidden"
    :metric-source="metricSource"
    :title="chartTitle"
    :subtitle="chartSubtitle"
    :chart-tooltip="chartTitleTooltip"
    :screenshot-legend="screenshotLegend"
    :screenshot-title="screenshotTitle"
    :screenshot-subtitle="screenshotSubtitle"
  >
    <BarChart
      v-if="isSuccess"
      :chart-id="chartId"
      :chart-data="barSeries"
      :width="chartWidth"
      :height="350"
      :config="{
        options: {
          plugins: {
            datalabels: {
              display: false,
            },
            tooltip: {
              external: setTooltipData,
            },
          },
          scales: {
            x: {
              title: {
                text: `${titleLabels.title.trim()} (${titleLabels.metricUnit})`,
                display: true,
              },
            },
          },
        },
      }"
      :show-cumulative-line="showCumulativeLine"
    />
    <template #tools>
      <OnxPercentileControl class="onx-dist-chart__percentile-control" v-model="percentile" />
      <ViewChartSqlButton v-if="sql" :sql="sql" :chartTitle="chartTitle" />
    </template>
  </OnxChartContainer>
</template>

<style lang="scss">
.onx-dist-chart {
  &__percentile-control {
    margin-right: auto;
  }
}
</style>
