<template>
  <div class="spotlight">
    <div v-if="matches.laptop.value" class="spotlight__sidebar">
      <OnxSidebar>
        <CompetitiveMenu />
      </OnxSidebar>
    </div>
    <div v-if="ready" class="spotlight__main">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CompetitiveMenu from '../../components/specialized/CompetitiveMenu.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar';
import { DEFAULT_USER_GROUP } from '@/constants/constants';
import ROUTES from '@/constants/routes';

export default defineComponent({
  name: 'CompetitiveIntelligence',
  components: {
    CompetitiveMenu,
    OnxSidebar,
  },
  beforeRouteUpdate(to, from, next) {
    this.checkDisclaimer();

    if (from.name === to.name) {
      this.track({
        dashboard: 'competitive',
        params: to.params,
        query: to.query,
        url: to.path,
        route: to.name,
      });
    }

    next();
  },
  setup() {
    const matches = useBreakpoints();

    return { matches };
  },
  data() {
    return {
      ready: false,
      ROUTES,
    };
  },
  computed: {
    ...mapGetters(['dashboardInfo', 'user']),
    ...mapGetters({
      mainMetric: 'metrics/primaryMetric',
      userGroup: 'competitive/userGroup',
    }),
  },
  watch: {
    mainMetric() {
      this.setUserGroup(this.mainMetric?.subcategory || this.userGroup || DEFAULT_USER_GROUP);
    },
  },
  mounted() {
    if (!this.user || !this.user.id) {
      this.setUser().then((res) => res && this.checkDisclaimer());
    }

    this.setUserSettings('competitive').then(() => {
      this.ready = true;
    });
  },
  methods: {
    ...mapActions([
      'setUser',
      'trackRoute',
      'track',
      'setUserSettings',
      'resetUser',
      'checkDisclaimer',
      'setUserGroup',
    ]),
  },
});
</script>
