<script setup>
import { computed } from 'vue';
import { useGetters, createNamespacedHelpers } from 'vuex-composition-helpers';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxNavigationHeader from '@/components/onx/spotlight-header/OnxNavigationHeader';
import InfoIcon from '@/components/onx/icons/InfoIcon';
import OnxVerticalDivider from '@/components/onx/OnxVerticalDivider';
import { METRIC_TYPE_NAMES, LATEST_STRING } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import router from '@/router';
import { getLongDate } from '@/utils/date';
import { metricToFullTitle } from '@/utils/titles';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import OnxPermissionGuard from '@/components/onx/OnxPermissionGuard.vue';
import { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';

import OnxIntlAndSingleCountryTabs from '@/components/onx/spotlight-header/OnxIntlAndSingleCountryTabs.vue';
import OnxSpotlightBreadcrumbs from '@/components/onx/spotlight-header/OnxSpotlightBreadcrumbs.vue';

const matches = useBreakpoints();

const { useGetters: useCompetitiveGetters } = createNamespacedHelpers('competitive');
const { useGetters: useChartsGetters } = createNamespacedHelpers('charts');
const { overviewStats } = useGetters(['overviewStats']);

const { dateRange, defaultMetricType } = useCompetitiveGetters(['defaultMetricType', 'dateRange']);
const { getTitleLabels } = useChartsGetters(['getTitleLabels']);
const primaryMetric = useSpotlightPrimaryMetric();
const dashboard = useCurrentDashboardName();

const isSpotlightOverview = router.currentRoute.value.name === ROUTES.CompetitiveOverview;
const isSpotlightConnectionCategory = router.currentRoute.value.name === ROUTES.CompetitiveConnectionCategory;
const isSpotlightDetails = router.currentRoute.value.name === ROUTES.CompetitiveDetails;

const dates = computed(() => {
  const compareTo = router.currentRoute.value.query.compareTo;
  const reference = overviewStats.value.find((a) => a.previous[compareTo]);

  let currentDate;

  if (!router.currentRoute.value.query.date || router.currentRoute.value.query.date === LATEST_STRING) {
    currentDate = dateRange.value;
  } else {
    currentDate = router.currentRoute.value.query.date;
  }

  return {
    current: getLongDate(currentDate),
    previous: reference ? getLongDate(reference.previous[compareTo].date) : undefined,
  };
});

const titleLabels = computed(() => {
  return getTitleLabels.value(primaryMetric.value, dates.value.previous, dates.value.current);
});

const title = computed(() => {
  if (isSpotlightOverview) {
    return `User Experience - ${METRIC_TYPE_NAMES[defaultMetricType.value] || defaultMetricType.value}`;
  }

  if (isSpotlightConnectionCategory) {
    const metricLabel = primaryMetric.value.name?.replace(/^\d\w\s/, '');

    return `By connection category${metricLabel ? `: ${metricLabel}` : ''}`;
  }

  return metricToFullTitle(primaryMetric.value);
});

const aggregationLabel = computed(() => {
  if (isSpotlightOverview || primaryMetric.value.subtype === 'onxcoveragesim') {
    return '90 days aggregation data';
  }

  return `${titleLabels.value.aggregation} data`;
});

const titleTooltip = computed(() => {
  if (primaryMetric.value && primaryMetric.value.key) {
    return TOOLTIP_MESSAGES[`spotlight_${primaryMetric.value.key}`];
  }

  return undefined;
});
</script>

<template>
  <OnxSpotlightBreadcrumbs />
  <OnxPermissionGuard :dashboard="dashboard" :topic="OnxPermissionTopics.IntlSpotlight">
    <OnxIntlAndSingleCountryTabs />
  </OnxPermissionGuard>
  <OnxNavigationHeader class="onx-spotlight-header-nav" :title="title" :tooltip="titleTooltip">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
    <template #info>
      <div v-if="isSpotlightOverview" class="onx-navigation-header__info">
        {{ dates.previous }} - {{ dates.current }}

        <OnxVerticalDivider :spacing="matches.desktop.value ? 16 : 8" class="onx-navigation-header__vertical-divider" />
      </div>

      <div v-if="aggregationLabel" class="onx-navigation-header__info">
        <span>{{ aggregationLabel }}</span>
        <VTooltip>
          <InfoIcon class="onx-navigation-header__info__icon" smaller />

          <template #popper>
            <div class="tooltip-content">
              <span>{{ aggregationLabel }} ending on the day before {{ dates.current }}</span>
              <template v-if="!isSpotlightDetails">
                <br />
                Compared to previous {{ aggregationLabel }} ending on the day before {{ dates.previous }}
              </template>

              <br />
              <br />
              <span>
                Spotlight Dashboard uses an "exclusive" end date convention. This means that for a 90 day
                <br />aggregation time period from January 1st to March 31st, Spotlight Dashboard will show <br />"date
                ending April 1st."
              </span>
            </div>
          </template>
        </VTooltip>

        <OnxVerticalDivider :spacing="matches.desktop.value ? 16 : 8" class="onx-navigation-header__vertical-divider" />
      </div>

      <div v-if="isSpotlightDetails" class="onx-navigation-header__info">
        <span>{{ titleLabels.metricUnit }}</span>

        <OnxVerticalDivider :spacing="matches.desktop.value ? 16 : 8" class="onx-navigation-header__vertical-divider" />
      </div>
    </template>
  </OnxNavigationHeader>
</template>
