import { METRIC_TYPE_OPTIONS } from '@/constants/constants';
import { MetricDescriptor } from '@/types/MetricDescriptor';

const getSpotlightConnectionCategories = (metrics: MetricDescriptor[]) => {
  const isConnectionCategoryAvailable = (connectionCategory: string) => {
    return metrics.some((m) => m.type === connectionCategory);
  };

  return METRIC_TYPE_OPTIONS.filter((tech) => isConnectionCategoryAvailable(tech.value));
};

export default getSpotlightConnectionCategories;
