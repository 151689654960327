<script setup>
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxPaper from '@/components/onx/OnxPaper';
import OnxHeadline from '@/components/onx/typography/OnxHeadline';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';

defineProps({
  title: {
    type: String,
    required: true,
  },
  tooltip: {
    type: String,
    required: false,
  },
});

const matches = useBreakpoints();

const { useGetters: useLocationGetters } = createNamespacedHelpers('location');

const { currentCountry, currentLocation } = useLocationGetters(['currentLocation', 'currentCountry']);
</script>

<template>
  <OnxPaper class="onx-navigation-header-root">
    <div class="onx-navigation-header-root__header" v-if="$slots.actions">
      <div class="onx-navigation-header__actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="onx-navigation-header">
      <div class="onx-navigation-header__title--wrapper">
        <CustomTooltip :message="tooltip" placement="right" v-if="!!tooltip">
          <OnxHeadline class="onx-navigation-header__title">
            {{ title }}
          </OnxHeadline>
        </CustomTooltip>
        <OnxHeadline v-else class="onx-navigation-header__title">
          {{ title }}
        </OnxHeadline>
        <span v-if="currentLocation.name !== currentCountry?.name" class="onx-navigation-header__subtitle">
          {{ currentLocation.name }}
        </span>
      </div>

      <div class="onx-navigation-header__info-section">
        <slot name="info" />

        <div class="onx-navigation-header__location">
          {{ matches.mobile.value && !matches.tablet.value ? currentCountry?.iso3 : currentCountry?.name }}
        </div>
      </div>
    </div>

    <div v-if="$slots.tabs" class="onx-navigation-header__tabs-container">
      <slot name="tabs" />
    </div>
  </OnxPaper>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.onx-navigation-header__settings.v-popper--shown path {
  fill: var(--teal-500);

  @include desktop {
    fill: none;
    stroke: var(--teal-500);
  }
}

.onx-navigation-header {
  &__actions {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &__icon {
      margin-left: 4px;
    }

    .v-popper {
      display: flex;
    }
  }

  &__vertical-divider {
    height: 18px;
    margin: 0 8px;

    @include desktop {
      margin: 0 16px;
    }
  }
}
</style>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';
@import 'scss/onx-breakpoints.module';

.tooltip-content {
  text-align: center;
  max-width: 316px;
}

.onx-navigation-header-root {
  margin-bottom: 8px;
  padding: 16px 0;

  @include tablet {
    margin-bottom: 16px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 24px;
    padding: 0 16px;
  }
}

.onx-navigation-header {
  padding-left: 16px;
  padding-right: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @include tablet {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__info-section {
    height: 18px;
    font-size: pxToRem(12);

    display: flex;
    align-items: center;

    & > * {
      border-right: 1px solid var(--charcoal-150);

      &:last-child {
        border-right: none;
      }
    }
  }

  &__title {
    margin: 0;
    flex-grow: 1;

    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include laptop {
        white-space: nowrap;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    color: var(--charcoal-300);
    font-size: pxToRem(18);
    font-weight: 300;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include tablet {
      font-size: pxToRem(20);
    }

    @include desktop {
      font-size: pxToRem(24);
    }
  }

  &__aggregation {
    font-size: pxToRem(14);

    .onx-icon {
      width: 10px;
      height: 10px;
      margin-left: 5px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    margin-right: 0;

    > span {
      width: 24px;
      height: 24px;
    }
  }

  &__tabs-container {
    margin-bottom: -16px;
  }

  &__breadcrumbs {
    position: relative;
    left: -6px;
  }
}
</style>
