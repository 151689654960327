import { computed, ComputedRef, MaybeRef, Ref, ref, unref } from 'vue';
import { useQuery, keepPreviousData, UseQueryReturnType } from '@tanstack/vue-query';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';
import type { DatasetsAPIDataStructuresEnum } from '@/types/MetricStructures';
import { PaginatedMetricResponse } from '@/types/PaginatedMetricResponse';

export type PaginatedDatasetReturnType<T extends DatasetsAPIDataStructuresEnum> = {
  page: Ref<number>;
  totalCount: ComputedRef<number>;
  gotoPage: (page: number) => void;
  query: UseQueryReturnType<AxiosResponse<PaginatedMetricResponse<T>>, Error>;
  queryParams: ComputedRef<Record<string, string | number>>;
  url: ComputedRef<string>;
};

export type UsePaginatedDatasetOptions = {
  canonicalNetworkIDs?: MaybeRef<string[]> | MaybeRef<number[]>;
  connectionCategories?: MaybeRef<string[] | undefined>;
  endDate?: MaybeRef<Date | undefined>;
  enabled?: MaybeRef<boolean | undefined>;
  sortColumnKey?: MaybeRef<string | undefined>;
  sortDirection?: MaybeRef<'asc' | 'desc' | undefined>;
  keepPreviousData?: boolean;
  aggregation?: MaybeRef<string | undefined>;
  geohashes?: MaybeRef<string[]>;
  requestParams?: MaybeRef<Record<string, string | number> | undefined>;
};

/**
 * Used to GET paginated dataset metrics
 * Example URL: http://platform-test.opensignal.com/api/api/v2/onx360/datasets/onx360_cdnip_download/?location_ids=32&page=2&order_by=-download
 */
export const usePaginatedDataset = <T extends DatasetsAPIDataStructuresEnum>(
  dashboard: Dashboards,
  dataset: MaybeRef<string>,
  location: MaybeRef<string | number>,
  options?: UsePaginatedDatasetOptions,
): PaginatedDatasetReturnType<T> => {
  // Store the current desired page
  // (can be overridden if there isn't enough data for example if filtering changes the result set to be shorter)
  const page = ref<number>(1); // 1-index page numbers. 1 is first page.

  // Function to go to a specific page. Will be overridden by the max page
  const gotoPage = (newPage: number) => {
    if (newPage > 0) {
      page.value = newPage;
    }
  };

  // get ready to query the dataset
  const queryParams = computed(() => {
    const p: Record<string, string | number> = {
      page: page.value,
      location_ids: unref(location),
    };

    const sortColumnKeyValue = unref(options?.sortColumnKey);
    const sortDirectionValue = unref(options?.sortDirection);
    if (sortColumnKeyValue && sortDirectionValue) {
      switch (sortDirectionValue) {
        case 'asc':
          p['order_by'] = sortColumnKeyValue;
          break;
        case 'desc':
          p['order_by'] = `-${sortColumnKeyValue}`;
          break;
      }
    }

    const connectionCategories = unref(options?.connectionCategories);
    if (connectionCategories && connectionCategories.length > 0) {
      const connectionCategoryString = connectionCategories
        .map((category) => {
          const categoryLower = category.toLowerCase();
          if (categoryLower === 'lte') {
            return '4g';
          } else {
            return categoryLower;
          }
        })
        .join(',');
      p['connection_categories'] = connectionCategoryString;
    }

    const canonicalNetworkIDs = unref(options?.canonicalNetworkIDs);
    if (canonicalNetworkIDs && canonicalNetworkIDs.length > 0) {
      p['canonical_network_ids'] = canonicalNetworkIDs.join(',');
    }

    const endDateValue = unref(options?.endDate);
    if (endDateValue) {
      p['end_date'] = format(endDateValue, 'yyyy-MM-dd');
    }

    const aggregation = unref(options?.aggregation);
    if (aggregation) {
      p['aggregation_type'] = aggregation;
    }

    const geohashes = unref(options?.geohashes);
    if (geohashes && geohashes.length > 0) {
      p['geohashes'] = geohashes.join(',');
    }

    const otherRequestParams = unref(options?.requestParams);
    if (otherRequestParams && Object.keys(otherRequestParams).length > 0) {
      Object.assign(p, otherRequestParams);
    }

    return p;
  });

  const url = computed(() => {
    const datasetValue = unref(dataset);
    return `/${dashboard}/datasets/${datasetValue}/`;
  });

  const keepPreviousDataOption = options?.keepPreviousData ?? true;

  // Query the dataset
  const query = useQuery<AxiosResponse<PaginatedMetricResponse<T>>>({
    queryKey: ['datasets', url, queryParams],
    queryFn: async () => {
      const response = await osApi.get(url.value, {
        params: queryParams.value,
      });

      if (response.status === 200 && response.data.data) {
        const maxPage = Math.ceil(response.data.record_count ?? 0 / 20);
        page.value = Math.max(1, Math.min(page.value, maxPage));
      }

      return response;
    },
    staleTime: 1 * 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false,
    enabled: options?.enabled,
    retry: 1,
    placeholderData: keepPreviousDataOption ? keepPreviousData : undefined,
  });

  return {
    gotoPage,
    page: page,
    totalCount: computed(() => query.data?.value?.data.record_count || 0),
    query,
    queryParams,
    url,
  };
};
