<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import { FieldGroup } from '@/components/menu';
import OnxRadioButton from '@/components/onx/OnxRadioButton.vue';
import InfoIcon from '@/components/onx/icons/InfoIcon.vue';
import { DEFAULT_USER_GROUP } from '@/constants/constants';
import getSpotlightUserGroups from '@/components/specialized/getSpotlightUserGroups';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useAnalytics from '@/composables/useAnalytics';

type Props = {
  openUserGroupInfoModal: () => void;
};

defineProps<Props>();

const { track } = useAnalytics();
const queryParams = useSpotlightQueryParams({
  userGroup: DEFAULT_USER_GROUP,
});

const store = useStore();

const userGroups = computed(() => {
  return getSpotlightUserGroups(store.getters['metrics'], queryParams.connectionCategory.toValue());
});

const onUserGroupChange = (value: string) => {
  queryParams.userGroup.onChange(value);
  track('user group change', { newUserGroup: value });
};
</script>

<template>
  <FieldGroup label="User Groups" v-if="userGroups.length">
    <template #tooltip>
      <InfoIcon class="FieldGroup__tooltipIcon" @click="openUserGroupInfoModal()" />
    </template>
    <OnxRadioButton
      v-for="userGroup in userGroups"
      name="user-groups-radio"
      :key="userGroup.value"
      :model-value="queryParams.userGroup.selectedValue.value"
      @update:model-value="onUserGroupChange"
      :value="userGroup.value"
      :label="userGroup.label"
    />
  </FieldGroup>
</template>
