<script setup lang="ts">
import { computed } from 'vue';

import {
  PeriodComparisonGrid,
  PeriodComparisonCurrentItem,
  PeriodComparisonPreviousItem,
} from '@/components/onx/period-comparisons';
import ThreeSixtyPage from '@/360/ThreeSixtyPage.vue';
import useLocations from '@/composables/useLocations';
import { Dashboards, DashboardTopics } from '@/constants/dashboards';
import useDashboardTopicCountryInfo from '@/composables/useDashboardTopicCountryInfo';

import type { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import useFilters from '@/composables/useFilters';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';
import Routes from '@/constants/routes';
import { MapEvents } from '@/components/visual/map/MapEvents';

import { MapOperatorSelector, useMapOperatorSelector } from '@/components/map-operator-selector';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import { getPeriodString } from '@/360/base-station/getPeriodString';

const { aggregation } = useFilters(Dashboards.ThreeSixty);
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);
const { selectedMapOperator } = useMapOperatorSelector(Dashboards.ThreeSixty);
const { countryIso3, locationId } = useLocations(Dashboards.ThreeSixty);
const { data: dashboardTopicCountryInfoResponse, isSuccess } = useDashboardTopicCountryInfo(
  DashboardTopics.OVERSHOOTING,
  countryIso3.value,
);

const mapBboxFilterStore = useMapBboxFilterStore();

const previousPeriod = computed(() => {
  if (!isSuccess.value || !dashboardTopicCountryInfoResponse.value) {
    return '';
  }

  return getPeriodString(
    dashboardTopicCountryInfoResponse.value.data.report_dates.previous_start_date,
    dashboardTopicCountryInfoResponse.value.data.report_dates.previous_end_date,
  );
});

const currentPeriod = computed(() => {
  if (!isSuccess.value || !dashboardTopicCountryInfoResponse.value) {
    return '';
  }

  return getPeriodString(
    dashboardTopicCountryInfoResponse.value.data.report_dates.current_start_date,
    dashboardTopicCountryInfoResponse.value.data.report_dates.current_end_date,
  );
});

const metrics = chartMetricDefinitionsByPage[Routes.ThreeSixtyBaseStationOvershooting] as Record<
  string,
  ChartMetricDefinition[]
>;

const buildPreviousMetric = (metric: any) => ({
  ...metric,
  requestParams: { period_split: 0 },
  exportAttrs: {
    ...metric.exportAttrs,
    subtitle: previousPeriod,
  },
});
const buildCurrentMetric = (metric: any) => ({
  ...metric,
  requestParams: { period_split: 1 },
  exportAttrs: {
    ...metric.exportAttrs,
    subtitle: currentPeriod,
  },
});
</script>

<template>
  <ThreeSixtyPage class="onx-360-overshooting">
    <OnxSelectorBlock title="Map filters">
      <MapOperatorSelector />
    </OnxSelectorBlock>

    <OnxSwyftMap
      v-if="selectedMapOperator"
      :key="`${locationId}_${mapBboxFilterStore.resetKey}`"
      map-endpoint="maps/overshooting"
      title="Overshooting Likelihood"
      :network-operators="[selectedMapOperator]"
      :geohash-level="7"
      class="overshooting-map"
      :location-id="locationId"
      @[MapEvents.NewBounds]="mapBboxFilterStore.handleNewBounds"
      enable-bbox-filtering
    />

    <PeriodComparisonGrid :previousPeriod="previousPeriod" :currentPeriod="currentPeriod">
      <template v-for="metric in metrics.byOperator" :key="`${metric.metricSubtype}_${metric.connectionCategory}`">
        <PeriodComparisonPreviousItem>
          <ChartManager
            :dashboard="Dashboards.ThreeSixty"
            :metric="buildPreviousMetric(metric)"
            :connection-category="metric.connectionCategory!"
            :connection-category-label="metric.connectionCategory"
            :location="locationId"
            :geohashes="[]"
            :aggregation="aggregation"
            :operators="selectedOperators"
            :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
          />
        </PeriodComparisonPreviousItem>
        <PeriodComparisonCurrentItem>
          <ChartManager
            :dashboard="Dashboards.ThreeSixty"
            :metric="buildCurrentMetric(metric)"
            :connection-category="metric.connectionCategory!"
            :connection-category-label="metric.connectionCategory"
            :location="locationId"
            :geohashes="[]"
            :aggregation="aggregation"
            :operators="selectedOperators"
            :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
          />
        </PeriodComparisonCurrentItem>
      </template>

      <template v-for="metric in metrics.forEachOperator">
        <template
          v-for="operator in selectedOperators"
          :key="`${metric.metricSubtype}_${metric.connectionCategory}_${operator.canonical_network_id}`"
        >
          <PeriodComparisonPreviousItem>
            <ChartManager
              :dashboard="Dashboards.ThreeSixty"
              :metric="buildPreviousMetric(metric)"
              :connection-category="metric.connectionCategory!"
              :connection-category-label="metric.connectionCategory"
              :location="locationId"
              :geohashes="[]"
              :aggregation="aggregation"
              :operators="selectedOperators"
              :main-operator="operator"
              :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
            />
          </PeriodComparisonPreviousItem>
          <PeriodComparisonCurrentItem>
            <ChartManager
              :dashboard="Dashboards.ThreeSixty"
              :metric="buildCurrentMetric(metric)"
              :connection-category="metric.connectionCategory!"
              :connection-category-label="metric.connectionCategory"
              :location="locationId"
              :geohashes="[]"
              :aggregation="aggregation"
              :operators="selectedOperators"
              :main-operator="operator"
              :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
            />
          </PeriodComparisonCurrentItem>
        </template>
      </template>
    </PeriodComparisonGrid>
  </ThreeSixtyPage>
</template>

<style lang="scss">
@use 'scss/mixins';
@import 'scss/onx-breakpoints.module';

.overshooting-map {
  margin-bottom: 8px;

  @include tablet() {
    margin-bottom: 16px;
  }
}
</style>
