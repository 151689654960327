<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import { FieldGroup } from '@/components/menu';
import OnxRadioButton from '@/components/onx/OnxRadioButton.vue';
import { METRIC_TYPES } from '@/constants/constants';
import getSpotlightConnectionCategories from '@/components/specialized/getSpotlightConnectionCategories';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useAnalytics from '@/composables/useAnalytics';

const { track } = useAnalytics();
const queryParams = useSpotlightQueryParams({
  connectionCategory: METRIC_TYPES.Overall,
});

const store = useStore();
const connectionCategories = computed(() => {
  return getSpotlightConnectionCategories(store.getters['metrics']);
});

const onConnectionCategoryChange = (value: string) => {
  queryParams.connectionCategory.onChange(value);
  track('connection category change', { newConnectionCategory: value });
};
</script>

<template>
  <FieldGroup label="Connection category">
    <OnxRadioButton
      v-for="category in connectionCategories"
      name="connection-category-radio"
      :key="category.value"
      :model-value="queryParams.connectionCategory.selectedValue.value"
      @update:model-value="onConnectionCategoryChange"
      :value="category.value"
      :label="category.label"
    />
  </FieldGroup>
</template>
