import { useQuery } from '@tanstack/vue-query';

import type { Dashboards } from '@/constants/dashboards';
import type { RefLike } from '@/types/helpers/RefLike';
import osApi from '@/api/osApi';
import { HeavyDbPolygonResponse } from '@/types/PolygonResponse';

type Options = {
  location: RefLike<number>;
  enabled?: RefLike<boolean>;
};

const useHeavyDbPolygons = (dashboard: Dashboards, options: Options) => {
  const { enabled, location } = options;

  return useQuery({
    queryKey: ['heavy-db-polygons', location],
    queryFn: () =>
      osApi.get<HeavyDbPolygonResponse>(`${dashboard}/geohashes`, {
        params: {
          parent_location_id: location.value,
        },
      }),
    staleTime: 60 * 60 * 1000,
    enabled,
    select: (response) => {
      const mappedFeatures = response.data.features.features.map((f, index) => ({
        ...f,
        /**
         * This ID is here because Mapbox requires a number (or string that can be parsed to number) as ID
         * Geohashes come in as alphanumeric, so parsing them to number is not possible
         * This will fail when we have more than a billion locations and the location of a city matches that of a geohash
         * That's unlikely to happen
         */
        id: 1000000000 + index,
      }));

      return {
        ...response,
        data: {
          ...response.data,
          features: {
            ...response.data.features,
            features: mappedFeatures,
          },
        },
      };
    },
  });
};

export default useHeavyDbPolygons;
